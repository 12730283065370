import Link from "next/link";
import CmsMenuDropdownMobile from "./CmsMenuDropdownMobile";

export { NavLinkMobile };

function NavLinkMobile({ link, mobile = false }) {
  if (typeof link != "undefined") {
    if (typeof link.navigation == "undefined" || link.navigation.length == 0) {
      // console.log(link);
      return (
        <div className="flex items-start">
          <div className="dropdown-toggle"></div>
          <Link href={link.path != "" ? link.path : "/"}
              className={`${link.active ? "active" : ""}`}
              onClick={() => {
                document.body.classList.remove("mobileMenuOpened");
                document
                  .querySelector("#burger")
                  .classList.remove("menu_open");
                  document.getElementById("menu_mobile_overlay").classList.remove("active");
                  document.getElementById("menu_mobile").classList.remove("active");
                  document.body.classList.remove("slide-left");
              }}
            >
              {link.name}
          </Link>
        </div>
      );
    } else {
      return <CmsMenuDropdownMobile link={link} mobile={mobile} />;
    }
  } else {
    return <></>;
  }
}
