import React from "react";
import Link from "next/link";

export default function InstagramIcon() {
  return (
    <Link href="https://www.instagram.com/explore/locations/1031068256/tarponville-fishing-lodge/">
      <a target="_blank">
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 6.8125C10.5 6.8125 7.3125 10.0625 7.3125 14C7.3125 18 10.5 21.1875 14.5 21.1875C18.4375 21.1875 21.6875 18 21.6875 14C21.6875 10.0625 18.4375 6.8125 14.5 6.8125ZM14.5 18.6875C11.9375 18.6875 9.8125 16.625 9.8125 14C9.8125 11.4375 11.875 9.375 14.5 9.375C17.0625 9.375 19.125 11.4375 19.125 14C19.125 16.625 17.0625 18.6875 14.5 18.6875ZM23.625 6.5625C23.625 7.5 22.875 8.25 21.9375 8.25C21 8.25 20.25 7.5 20.25 6.5625C20.25 5.625 21 4.875 21.9375 4.875C22.875 4.875 23.625 5.625 23.625 6.5625ZM28.375 8.25C28.25 6 27.75 4 26.125 2.375C24.5 0.75 22.5 0.25 20.25 0.125C17.9375 0 11 0 8.6875 0.125C6.4375 0.25 4.5 0.75 2.8125 2.375C1.1875 4 0.6875 6 0.5625 8.25C0.4375 10.5625 0.4375 17.5 0.5625 19.8125C0.6875 22.0625 1.1875 24 2.8125 25.6875C4.5 27.3125 6.4375 27.8125 8.6875 27.9375C11 28.0625 17.9375 28.0625 20.25 27.9375C22.5 27.8125 24.5 27.3125 26.125 25.6875C27.75 24 28.25 22.0625 28.375 19.8125C28.5 17.5 28.5 10.5625 28.375 8.25ZM25.375 22.25C24.9375 23.5 23.9375 24.4375 22.75 24.9375C20.875 25.6875 16.5 25.5 14.5 25.5C12.4375 25.5 8.0625 25.6875 6.25 24.9375C5 24.4375 4.0625 23.5 3.5625 22.25C2.8125 20.4375 3 16.0625 3 14C3 12 2.8125 7.625 3.5625 5.75C4.0625 4.5625 5 3.625 6.25 3.125C8.0625 2.375 12.4375 2.5625 14.5 2.5625C16.5 2.5625 20.875 2.375 22.75 3.125C23.9375 3.5625 24.875 4.5625 25.375 5.75C26.125 7.625 25.9375 12 25.9375 14C25.9375 16.0625 26.125 20.4375 25.375 22.25Z"
            fill="white"
          />
        </svg>
      </a>
    </Link>
  );
}
