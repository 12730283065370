import React, { useState, useEffect, useCallback } from "react";

function CmsTopArrow() {

  const [show, setShow] = useState(false);

  const onScroll = useCallback(event => {
    // const { pageYOffset, scrollY } = window;
    // console.log("yOffset", pageYOffset, "scrollY", scrollY);
    if (window.scrollY > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

useEffect(() => {
  window.addEventListener("scroll", onScroll, { passive: true });
  return () => {
     window.removeEventListener("scroll", onScroll, { passive: true });
  }
}, []);

  const goToTop = (e) => {
    // console.log(e, window)
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  return (
    <>
      <div
        onClick={goToTop}
        className={`back-to-top cursor-pointer fixed ${
          show ? "flex" : "hidden"
        } bottom-8 right-8 left-auto z-[999] h-10 w-10 items-center justify-center rounded-md theme-b2 text-white shadow-md transition duration-300 ease-in-out hover:bg-dark`}
      >
        <span className="mt-[6px] h-3 w-3 rotate-45 border-t border-l border-white"></span>
      </div>
    </>
  );
}

export default CmsTopArrow;
