export async function loadPage(page="homepage", locale='it') {
  var endpoint = process.env.CMS_API_URL + "/page/"+locale+"/" + page;
  // console.log(endpoint);
  try {
    const res = await fetch(endpoint, {
      headers: {
          "Content-Type":  "application/json; charset=utf8",
          "Authorization": process.env.CMS_API_KEY,
      }
    });
    const data = await res.json()
    return data
  } catch (error) {
		return null
	}
}

export async function loadSiteMap() {
  var endpoint = process.env.CMS_API_URL + "/sitemap";
  try {
    const res = await fetch(endpoint, {
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: process.env.CMS_API_KEY,
      },
    });
    const data = await res.json();
    return data;
  } catch (err) {
    return false;
  }
}

export async function sendMail(params) {
  const endpoint = process.env.CMS_API_URL + "/sendmail";
  const res = await fetch(endpoint, {
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json; charset=utf8",
      Authorization: process.env.CMS_API_KEY,
    },
    method: "POST",
  });
  const data = await res.json();
  return data;
}

export async function testApi(params) {
  return params.json();
}

export function getMappedTransUrl(path, fromLocale, toLocale) {
  path = path.split('/page/').join('/');
  path = path.split('/it/').join('/');

  let slug = path.split('/')[1];
  const slugs = path.split('/').slice(2);

  slug = slug == '' ? 'homepage' : slug;

  const mapping = {
    'it': [
      'homepage',
      'chi-siamo',
      'la-nostra-storia',
      'autorizzazioni-e-certificazioni',
      'politica-della-qualita',
      'servizi',
      'servizi-per-lindustria-siderurgica',
      'servizi-per-gli-impianti-di-recupero-riciclo',
      'servizi-di-gestione-rifiuti',
      'demolizioni',
      'video',
      'blog',
      'contatti',
    ],
    'en': [
      'homepage',
      'about-us',
      'corporate-history',
      'authorizations-and-certifications',
      'quality-policy',
      'services',
      'services-for-the-steel-industry',
      'services-for-recovery-recycling-plants',
      'waste-management-services',
      'demolitions',
      'videos',
      'blog',
      'contacts',
    ],
  }

  const found = mapping[fromLocale].findIndex((element) => element == slug);
  path = '/'+(toLocale == 'en' ? '' : toLocale+'/')+mapping[toLocale][found];

  return path;
}