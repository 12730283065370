import React, { useEffect, useRef, useState } from "react";
import { NavLinkMobile } from "./NavLinkMobile";
import Link from "next/link";

function CmsMenuDropdownMobile({ link }) {
  let [isOpen, setIsOpen] = useState(false);

  let button = useRef(null);

  useEffect(() => {}, []);

  return (
    <div className="relative">
      <div className="link-block">
        <Link href={link.path != "" ? link.path : "/"}
          ref={button}
          onClick={() => {
            document.body.classList.remove("mobileMenuOpened");
            document
              .querySelector("#burger")
              .classList.remove("menu_open");
              document.getElementById("menu_mobile_overlay").classList.remove("active");
              document.getElementById("menu_mobile").classList.remove("active");
              document.body.classList.remove("slide-left");
          }}
          className={`${isOpen ? "isOpen" : ""} ${link.active ? "active" : ""}`}
        >
            {link.name}
        </Link>
        {!isOpen ? (
          // <ChevronDownIcon className="dropdown-icon-down"  onClick={(event) => { setIsOpen(true) }} />
          <span onClick={(event) => { setIsOpen(true) }}>+</span>
        ) : (
          // <ChevronUpIcon className="dropdown-icon-up"  onClick={(event) => { setIsOpen(false) }} />
          <span onClick={(event) => { setIsOpen(false) }}>-</span>
        )}
      </div>

      <div className="dropdown-panel">
        <div
          className={`dropdown-panel-inner columns-${link.columns} ${
            isOpen ? "block" : "hidden"
          }`}
        >
          {link.navigation.map(function (link, i) {
            return <NavLinkMobile link={link} key={i} exact />;
          })}
        </div>
      </div>
    </div>
  );
}

export default CmsMenuDropdownMobile;
