import React from "react";
import Link from "next/link";

export default function FacebookIcon() {
  return (
    <Link href="https://www.facebook.com/tarponville">
      <a target="_blank">
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.5 0H3.5C1.8125 0 0.5 1.375 0.5 3V25C0.5 26.6875 1.8125 28 3.5 28H12.0625V18.5H8.125V14H12.0625V10.625C12.0625 6.75 14.375 4.5625 17.875 4.5625C19.625 4.5625 21.375 4.875 21.375 4.875V8.6875H19.4375C17.5 8.6875 16.875 9.875 16.875 11.125V14H21.1875L20.5 18.5H16.875V28H25.5C27.125 28 28.5 26.6875 28.5 25V3C28.5 1.375 27.125 0 25.5 0Z"
            fill="white"
          />
        </svg>
      </a>
    </Link>
  );
}
