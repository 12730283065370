import React from "react";
import Image from "next/image";

export default function MiniLogo({ size }) {
  return (
    <>
    <Image
        alt={` `}
        src="/images/logo.png"
        // layout="fixed"
        width="214"
        height="50"
        priority={true}
      />
    </>
  )
}