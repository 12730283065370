import React from "react";
import Image from "next/image";

export default function Logo({ size }) {
  return (
    <>
    <Image
        alt={` `}
        src="/images/logo.png"
        // layout="fixed"
        width="256"
        height="60"
        priority={true}
      />
    </>
  )
}