import dynamic from "next/dynamic";

import CmsSocial from "./CmsSocial";

import CmsForm from "./CmsForm";
import Logo from "./Logo";
// import CmsSwiperIntro from "./CmsSwiperIntro";

const dynamicComponents = {
  // slideshow: dynamic(() => import("./CmsSlideshow")),
  text: dynamic(() => import("./CmsText")),
  image: dynamic(() => import("./CmsImage")),
  card: dynamic(() => import("./CmsCard")),
  cardGroup: dynamic(() => import("./CmsCardGroup")),
  privacyPolicy: dynamic(() => import("./CmsPrivacyPolicy")),
  swiper: dynamic(() => import("./CmsSwiper")),
  swiperIntro: dynamic(() => import("./CmsSwiperIntro")),
  video: dynamic(() => import("./CmsVideo")),
  attachment: dynamic(() => import("./CmsAttachment")),
  link: dynamic(() => import("./CmsButton")),
  collection: dynamic(() => import("./CmsCollection")),
  item: dynamic(() => import("./CmsCollectionItem")),
  customBlock: dynamic(() => import("./CmsCustom")),
  youtube: dynamic(() => import("./CmsYouTube")),
};

function CmsRow({ row, next_row = null, page_id = "" }) {
  return (
    <section id={`${row.id ?? ""}`}>
      <div className={row.full ? "container-xxl mx-auto" : `container mx-auto`}>
        <div
          className={
            row.columns != "full" && row.columns > 1
              ? `griglia grid-cols-${row.columns} gap-8`
              : ""
          }
        >
          {row.components.map(function (component, i) {
            component.name = component.name == "card-group" ? "cardGroup" : component.name;
            component.name = component.name == "custom-block" ? "customBlock" : component.name;
            component.name = component.name == "customBlock" && component.id == 'privacy-policy' ? "privacyPolicy" : component.name;
            component.name = component.id == "introslide" ? "swiperIntro" : component.name;
            component.name = component.name == "collection" && component.options.items.length > 0 && component.options.items[0].detail ? "item" : component.name;

            const Component = dynamicComponents[component.name];

            component.options.columns =
              typeof component.options.columns != "undefined"
                ? component.options.columns
                : 1;

            if (
              typeof Component != "undefined" && component.id != "logistics_for_scrap"
            ) {
              return (
                <div
                  className={`col-span-${component.options.columns}`}
                  key={i}
                >
                  <Component
                    component={component}
                    row={row}
                    next_row={next_row}
                    page_id={page_id}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
}

export default CmsRow;
