import CmsRow from "./CmsRow";
import CmsTopArrow from "./CmsTopArrow";

function CmsFooter({ structure }) {
  return (
    <footer id="footer">
      {structure.footer.map(function (row, i) {
        return (
          <CmsRow row={row} key={i} />
        )
      })}
    </footer>
  );
}

export default CmsFooter;
