import React from "react";

import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import YoutubeIcon from "./icons/YoutubeIcon";
import TripadvisorIcon from "./icons/TripadvisorIcon";

export default function CmsSocial() {
  return (
    <div className="social">
        <FacebookIcon />
        <InstagramIcon />
        <YoutubeIcon />
        <TripadvisorIcon />
    </div>
  );
}
