import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { getMappedTransUrl } from "../lib/api-lib";

function LangMenu({}) {
  const [show, setShow] = useState(false)
  const dropdown = useRef(null);
  
  const { asPath, locale, locales} = useRouter()

  const curLang = locale
  const languages = locales

  useEffect(() => {
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setShow(false);
      }
    }
    window.addEventListener("click", handleClick);
    // clean up
    return () => window.removeEventListener("click", handleClick);
  }, [show])

  const hideMenu = (e) => {
    document.getElementById("menu_mobile_overlay").classList.remove("active");
    document.getElementById("menu_mobile").classList.remove("active");
    document.body.classList.remove("slide-left");
  };

  function onClick() {
      setShow(!show);
  }
  function onClickHide() {
      setShow(!show);
      hideMenu();
  }

  return (
    <div className="relative" ref={dropdown} >
      <button 
      onClick={onClick} 
      className=""
      >
        <Image
          alt={` `}
          src={`/images/flags/${curLang}.png`}
          width="18"
          height="12"
          priority={true}
        />
      </button>

      <div className={`absolute ${show ? 'block' : 'hidden'} bottom-0`}>
        {languages.map(function(lang, i) {
          if(curLang != lang) {
            return (
                <div 
                key={i}
                className="lang"
                >
                  <a href={getMappedTransUrl(asPath, curLang, lang)}>
                      <Image
                        alt={` `}
                        src={`/images/flags/${lang}.png`}
                        width="18"
                        height="12"
                        priority={true}
                      />
                  </a>
                </div>
            )
          }
        })}
      </div>

    </div>
  );
}

export default LangMenu;
