import React from "react";
import Link from "next/link";

export default function TripadvisorIcon() {
  return (
    <Link href="https://www.tripadvisor.com/Hotel_Review-g1644063-d613574-Reviews-Tarponville-Manzanillo_Province_of_Limon.html">
      <a target="_blank">
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_21_84)">
            <path
              d="M26.2114 9.9027L28.5 7.41298H23.425C20.8843 5.67739 17.816 4.66666 14.4979 4.66666C11.1837 4.66666 8.12392 5.67967 5.58755 7.41298H0.5L2.78831 9.9027C1.38557 11.1826 0.506258 13.0262 0.506258 15.073C0.506258 18.9359 3.63804 22.0677 7.50092 22.0677C9.33607 22.0677 11.0076 21.3599 12.2556 20.203L14.4976 22.644L16.7395 20.2052C17.9875 21.3622 19.6574 22.0677 21.4922 22.0677C25.3551 22.0677 28.4912 18.9359 28.4912 15.073C28.4932 13.024 27.6139 11.1806 26.2111 9.9027H26.2114ZM7.50292 19.8067C4.88802 19.8067 2.76926 17.6879 2.76926 15.073C2.76926 12.4581 4.88831 10.3394 7.50292 10.3394C10.1178 10.3394 12.2366 12.4584 12.2366 15.073C12.2366 17.6876 10.1175 19.8067 7.50292 19.8067ZM14.4999 14.9353C14.4999 11.8203 12.2346 9.14628 9.24475 8.00411C10.8617 7.3282 12.6351 6.95326 14.4979 6.95326C16.3603 6.95326 18.136 7.3282 19.753 8.00411C16.7654 9.14856 14.5001 11.8203 14.5001 14.9353H14.4999ZM21.4945 19.8067C18.8796 19.8067 16.7609 17.6879 16.7609 15.073C16.7609 12.4581 18.8799 10.3394 21.4945 10.3394C24.1094 10.3394 26.2282 12.4584 26.2282 15.073C26.2282 17.6876 24.1091 19.8067 21.4945 19.8067ZM21.4945 12.5896C20.1234 12.5896 19.0131 13.6999 19.0131 15.071C19.0131 16.4419 20.1234 17.5522 21.4945 17.5522C22.8654 17.5522 23.9757 16.4419 23.9757 15.071C23.9757 13.7021 22.8654 12.5896 21.4945 12.5896ZM9.98439 15.073C9.98439 16.4439 8.87408 17.5542 7.50292 17.5542C6.13203 17.5542 5.02173 16.4439 5.02173 15.073C5.02173 13.7019 6.13203 12.5916 7.50292 12.5916C8.8738 12.5896 9.98439 13.7019 9.98439 15.073Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_21_84">
              <rect
                width="28"
                height="28"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </a>
    </Link>
  );
}
