import React, { useState, useEffect } from "react";
import Head from "next/head";
import CmsRow from "./CmsRow";
import CmsFooter from "./CmsFooter";
import CmsTopArrow from "./CmsTopArrow";
import { useRouter } from "next/router";

import dynamic from "next/dynamic";

import Aos from "aos";
import "aos/dist/aos.css";

function CmsPage({ structure }) {
  const { asPath, locale, locales} = useRouter()

  const CmsMap = dynamic(() => import("./CmsMap"), {
    ssr: false,
  });

  useEffect(() => {
    if (document.readyState !== "loading") {
      /* HOMEPAGE */
      // applyAosToElement(".homepage #riga1 .checkContainer", "fade-left");
      // applyAosToElementByIndex(
      //   ".homepage #riga2 #card-group-1657726991 > div",
      //   0,
      //   "fade-right"
      // );
      // applyAosToElementByIndex(
      //   ".homepage #riga2 #card-group-1657726991 > div",
      //   1,
      //   "fade-up"
      // );
      // applyAosToElementByIndex(
      //   ".homepage #riga2 #card-group-1657726991 > div",
      //   2,
      //   "fade-left"
      // );
      // applyAosToElement(".homepage #riga3", "fade-up");
      // applyAosToElement(".homepage #riga4 .text", "fade-up");
      // applyAosToElement(".homepage #map-title .text", "fade-up");
      // applyAosToElement(".homepage #map-title .text", "fade-up");
      // applyAosToElement(".homepage .mapOverlayBox", "fade-right");
      Aos.init();

    }
  }, []);

  var iubendaImplementation = ``;

  if(locale == 'it') {
    iubendaImplementation = `
    <script type="text/javascript">
    var _iub = _iub || [];
    _iub.csConfiguration = {"floatingPreferencesButtonDisplay":"bottom-left","lang":"it","perPurposeConsent":true,"siteId":2241921,"whitelabel":false,"cookiePolicyId":37766545, "banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0073CE","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","closeButtonDisplay":false,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#0073CE","rejectButtonDisplay":true,"showPurposesToggles":true,"slideDown":false,"textColor":"#000000" }};
    </script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>`;
  } else {
    iubendaImplementation = `
    <script type="text/javascript">
    var _iub = _iub || [];
    _iub.csConfiguration = {"floatingPreferencesButtonDisplay":"bottom-left","lang":"en-GB","perPurposeConsent":true,"siteId":2241921,"whitelabel":false,"cookiePolicyId":74579925, "banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0073CE","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","closeButtonDisplay":false,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#0073CE","rejectButtonDisplay":true,"showPurposesToggles":true,"slideDown":false,"textColor":"#000000" }};
    </script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>`;
  }

  // const iubendaImplementation = ``;

  return (
    <>
      <Head>
        <title>{structure.page.title}</title>
        <meta name="description" content={structure.page.description} />
      </Head>
      <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div>
      <main
        className={`${structure.page.template} cmsPage`}
        id={`${structure.page.slug ?? ""}`}
      >
        {structure.rows.map(function (row, i) {
            var next_row = i < structure.rows.length-1 ? structure.rows[i+1] : null;
            if(row.id != 'introhome') {
              return <CmsRow row={row} next_row={next_row} page_id={structure.page.id} key={i} />;
            }
        })}
      </main>
      <CmsFooter structure={structure} />
      <CmsTopArrow />
    </>
  );
}

function applyAosToElement(selector, effect) {
  if (document.querySelector(selector)) {
    document.querySelector(selector).setAttribute("data-aos", effect);
  }
}

function applyAosToElementByIndex(selector, index, effect) {
  if (document.querySelectorAll(selector)[index]) {
    document.querySelectorAll(selector)[index].setAttribute("data-aos", effect);
  }
}

export default CmsPage;
